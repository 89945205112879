/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressModel } from './addressModel';

export interface ProfileModel {
  userId?: string;
  firstName?: string;
  lastName?: string;
  emailId?: string;
  preferredLocale?: string;
  roles?: Array<string>;
  phoneNumber?: string;
  addressModels?: Array<AddressModel>;
  allowedFunctionalitiesByRestaurantId?: { [key: string]: Array<string> };
}
export namespace ProfileModel {
  export type AllowedFunctionalitiesByRestaurantIdEnum =
    | 'KITCHEN'
    | 'HOME'
    | 'ITEMS'
    | 'RESTAURANTS'
    | 'MENU'
    | 'PRICE'
    | 'STOCK'
    | 'INVENTORY'
    | 'ORDERS'
    | 'CAPTAIN_ORDERS'
    | 'QRCODE'
    | 'TAX'
    | 'OFFER'
    | 'REPORTS'
    | 'GROUPS'
    | 'ROLES'
    | 'USERS'
    | 'DAILY_EXPENSES';
  export const AllowedFunctionalitiesByRestaurantIdEnum = {
    KITCHEN: 'KITCHEN' as AllowedFunctionalitiesByRestaurantIdEnum,
    HOME: 'HOME' as AllowedFunctionalitiesByRestaurantIdEnum,
    ITEMS: 'ITEMS' as AllowedFunctionalitiesByRestaurantIdEnum,
    RESTAURANTS: 'RESTAURANTS' as AllowedFunctionalitiesByRestaurantIdEnum,
    MENU: 'MENU' as AllowedFunctionalitiesByRestaurantIdEnum,
    PRICE: 'PRICE' as AllowedFunctionalitiesByRestaurantIdEnum,
    STOCK: 'STOCK' as AllowedFunctionalitiesByRestaurantIdEnum,
    INVENTORY: 'INVENTORY' as AllowedFunctionalitiesByRestaurantIdEnum,
    ORDERS: 'ORDERS' as AllowedFunctionalitiesByRestaurantIdEnum,
    CAPTAINORDERS: 'CAPTAIN_ORDERS' as AllowedFunctionalitiesByRestaurantIdEnum,
    QRCODE: 'QRCODE' as AllowedFunctionalitiesByRestaurantIdEnum,
    TAX: 'TAX' as AllowedFunctionalitiesByRestaurantIdEnum,
    OFFER: 'OFFER' as AllowedFunctionalitiesByRestaurantIdEnum,
    REPORTS: 'REPORTS' as AllowedFunctionalitiesByRestaurantIdEnum,
    GROUPS: 'GROUPS' as AllowedFunctionalitiesByRestaurantIdEnum,
    ROLES: 'ROLES' as AllowedFunctionalitiesByRestaurantIdEnum,
    USERS: 'USERS' as AllowedFunctionalitiesByRestaurantIdEnum,
    DAILY_EXPENSES:
      'DAILY_EXPENSES' as AllowedFunctionalitiesByRestaurantIdEnum,
  };
}
