import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartItemModel, MenuModel, MenuUsersRestControllerService, SmartDineResponseMenuItemMapModel, SmartDineResponseMenuModel } from 'src/app/swagger';
import { MenuItemMapping } from '../../models/menu-item-mapping/menu-item-mapping.model';
import { CartService } from '../../services/cart/cart.service';
import { AdminCartService } from '../../services/admincart/admincart.service';
import { QuantityChangeEvent } from '../quantity-selector/quantity-selector.component';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { ProfileModel } from '../../../swagger';
import { Role } from 'src/app/common/enums/role.enum';
import { RedirectUserService } from 'src/app/swagger/api/redirectUser.service';
import { Router } from '@angular/router';

interface MenuItemMappingsDictionary {
  [key: string]: MenuItemMapping;
}

interface Menu extends MenuModel {
  itemsMappings?: MenuItemMapping[];
}

interface SdEvent extends MouseEvent {
  value: any;
}

@Component({
  selector: 'app-sd-catalog-menu-display',
  template: `
    <ion-header *ngIf="isMobileView">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="onCloseClick()">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content *ngIf="isMobileView">
      <ion-list class="p-2">
        <ion-item lines="none" button [ngClass]="{'active': isAllActive}" (click)="onMenuClick($event, 'All')">
          <ion-label class="ion-text-center ion-text-md-end">All</ion-label>
          <ion-badge color="light" slot="end" [innerText]="totalFoodItems"></ion-badge>
        </ion-item>
        <ion-item lines="none" *ngFor="let menu of menus; let i = index"
          (click)="onMenuClick($event, menu)" button [ngClass]="{ 'active': (value?.menuId === menu.menuId && !isAllActive)}">
          <ion-label class="ion-text-center ion-text-md-end">
            <span [innerText]="menu.menuName"></span>
            <!-- <span>&nbsp;(</span> -->
              <!-- <span>)</span> -->
          </ion-label>
          <ion-badge color="light" slot="end" *ngIf="menu.itemsMappings" [innerText]="menu.itemsMappings.length"></ion-badge>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-list class="p-2" *ngIf="!isMobileView">
        <ion-item lines="none" button [ngClass]="{'active': isAllActive}" (click)="onMenuClick($event, 'All')">
          <ion-label class="ion-text-center ion-text-md-end">All</ion-label>
          <ion-badge color="light" slot="end" [innerText]="totalFoodItems"></ion-badge>
        </ion-item>
        <ion-item lines="none" *ngFor="let menu of menus; let i = index"
          (click)="onMenuClick($event, menu)" button [ngClass]="{ 'active': (value?.menuId === menu.menuId && !isAllActive)}">
          <ion-label class="ion-text-center ion-text-md-end">
            <span [innerText]="menu.menuName"></span>
            <!-- <span>&nbsp;(</span> -->
              <!-- <span>)</span> -->
          </ion-label>
          <ion-badge color="light" slot="end" *ngIf="menu.itemsMappings" [innerText]="menu.itemsMappings.length"></ion-badge>
        </ion-item>
      </ion-list>
  `,
  styleUrls: ['./catalog-menu-display.component.scss'],
})
export class MenuDisplayComponent {

  public static readonly id = 'menu-display-modal';

  @Input()
  public menus: Menu[] = [];

  @Input()
  public value: MenuModel | null = null;

  @Input()
  public totalFoodItems: number = 0;

  @Input() isMobileView = false;

  @Input() 
  public isAllActive = true;

  @Output()
  public menuClick: EventEmitter<SdEvent> = new EventEmitter();

  constructor(
    private modalController: ModalController,
  ) { }

  public onMenuClick(event: MouseEvent, menu: MenuModel | string) {
    let newEvent = event as SdEvent;
    if(menu === 'All'){
      this.isAllActive = true;
      newEvent.value = 'All';
    }else{
      this.isAllActive = false;
      newEvent.value = menu;
    }
    this.menuClick.emit(newEvent);

    if (this.isMobileView) {
      this.modalController.dismiss({ value: menu }, 'dismiss');
    }
  }

  public onCloseClick() {
    this.modalController.dismiss({}, 'dismiss');
  }
}

@Component({
  selector: 'app-sd-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnChanges {

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  @Input()
  public orderType: string | null = null;

  public menus: Menu[] | any = [];
  public itemMappings: MenuItemMappingsDictionary = {};
  public totalFoodItems = 0;
  public selectedMenu: MenuModel | null = null;

  private cartService: CartService | AdminCartService = null
  public isUserLoggedIn: boolean = false;
  public userProfile: ProfileModel = null;
  public filteredItems: any[] = [];

  constructor(
    private menuUsersRestControllerService: MenuUsersRestControllerService,
    private modalController: ModalController,
    private userProfileService: UserProfileService,
    private userCartService: CartService,
    private adminCartService: AdminCartService ,
    private router: Router,
    private redirectUserService: RedirectUserService
  ) { 
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
        this.isUserLoggedIn = userProfile && userProfile?.userId ? true : false;
        if (userProfile?.roles.includes(Role.ADMINS) || userProfile?.roles.includes(Role.RESTO_ADMINS)) {
          this.cartService = this.adminCartService;
        } else {
          this.cartService = this.userCartService;
        }
      }
    });
  }

  public ngOnInit(): void {
    this.setUrlParams();
  }

  public ngOnChanges(): void {
    this.init();
  }

  public onMenuListClick() {
    this.showMenus();
  }

  public onMenuClick(event: SdEvent): void {
    this.selectedMenu = event.value as MenuModel;
    this.scrollToMenu(this.selectedMenu);
  }

  public onQuantityChange(event: QuantityChangeEvent, item: MenuItemMapping) {
    const cartItem = item.cartItem;
    const quantity = event.value;
    cartItem.quantity = quantity;
    cartItem.price = cartItem.unitPrice * quantity;
    if (quantity === 0 && this.cartService != null) {
      this.cartService.removeItem(cartItem);
    } else {
      this.cartService.setItem(cartItem);
    }
  }

  public onAppear(_event: Event, menu: MenuModel) {
    //console.log('someone is in viewport -', menu.menuId);
    this.selectedMenu = menu;
  }

  private init() {
    this.isUserLoggedIn = this.userProfile && this.userProfile?.userId ? true : false;
    if (this.restaurantId && this.restaurantBranchId) {
      this.setMenus();
    }
    this.setUrlParams();
  }

  private setMenus() {
    // if (!this.restaurantId || !this.restaurantBranchId) { return; }
    this.menus = [];
    this.itemMappings = {};
    this.totalFoodItems = 0;
    this.menuUsersRestControllerService.getTopLevelMenus(this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuModel) => {
        this.menus = res.items;

        this.menus.forEach((each, index) => {
          if (index === 0) { this.selectedMenu = each; }
          this.setItems(each);
        });
        this.filteredItems = this.menus;
      },
      error: (err) => {
        console.error('Unable to get menus info!', err);
      },
    });
  }

  private setItems(menu: Menu) {
    menu.itemsMappings = [];
    this.menuUsersRestControllerService.getMenuItemMaps(this.restaurantId, this.restaurantBranchId, menu.menuId).subscribe({
      next: (res: SmartDineResponseMenuItemMapModel) => {
        menu.itemsMappings = res.items.map(e => MenuItemMapping.create(e));
        this.totalFoodItems += res.items.length;

        menu.itemsMappings.forEach((each) => {
          this.itemMappings[each.menuItemMapId] = each;
          this.setMenuItemCartInfo(each);
        });
      },
      error: (err) => {
        console.error('Unable to get menus info!', err);
      },
    });

    if (this.cartService != null) {
      // Updating the menu item mapping cart info in case of value changes in cart service..
      this.cartService.getItems().subscribe({
        next: () => {
          const itemMappings = Object.keys(this.itemMappings);
          itemMappings.forEach((each) => {
            this.setMenuItemCartInfo(this.itemMappings[each]);
          });
        }
      });
    }
    
  }

  private setMenuItemCartInfo(menuItemMap: MenuItemMapping) {
    if (this.cartService == null) {
      return;
    }
    let cartItem = this.cartService.getItem(menuItemMap.menuItemMapId);
    if (!cartItem) {
      cartItem = {
        itemId: menuItemMap.itemId,
        menuItemMapId: menuItemMap.menuItemMapId,
        menuItemType: CartItemModel.MenuItemTypeEnum.FOOD,
        unitPrice: menuItemMap.priceDetails.price,
        quantity: 0,
        price: 0,
        itemName: menuItemMap.menuItem.name,
        itemDescription: menuItemMap.menuItem.description,
      } as CartItemModel;
    }

    menuItemMap.cartItem = cartItem;
  }

  private async showMenus() {
    const modal = await this.modalController.create({
      component: MenuDisplayComponent,
      componentProps: {
        menus: this.menus,
        value: this.selectedMenu,
        totalFoodItems: this.totalFoodItems,
        isMobileView: true,
      },
    })
    modal.onDidDismiss().then((data: any) => {
      const selectedMenu = data?.data?.value as MenuModel;
      if (selectedMenu) {
        this.selectedMenu = selectedMenu;
        this.scrollToMenu(this.selectedMenu);
      }
    });
    await modal.present();
  }

  private scrollToMenu(menu: MenuModel | string) {
    let target = null;
    if(menu === 'All'){
      target = document.getElementById("AllMenus");
    }else{
      target = document.getElementById(menu['menuId']);
    }
    target?.scrollIntoView({ behavior: 'smooth' });
  }

  private setUrlParams(){
    if (!this.isUserLoggedIn) {
      const currentUrl = this.router.url;
      const encodedUrl = encodeURIComponent(currentUrl);
      this.redirectUserService.setRedirectUrl(encodedUrl);
      console.log("Url Saved Successfully!");
    }
  }

  public searchFoodItems(searchValue: string) {
    this.totalFoodItems = 0;
    if (searchValue === "" || !searchValue.trim()) {
        this.menus = this.filteredItems.slice(); //Restoring the original data
        this.totalFoodItems = this.menus.reduce((total, menu) => total + menu.itemsMappings.length, 0);
    } else {
        this.menus = this.filteredItems.map(menu => {
            const filteredMappings = menu.itemsMappings.filter(mapping => {
                const lowerSearchValue = searchValue.toLowerCase();
                const itemName = mapping.menuItem.name.toLowerCase();
                if (itemName.includes(lowerSearchValue)) {
                    this.totalFoodItems++;
                    return true;
                }
                return false;
            });
            return { ...menu, itemsMappings: filteredMappings }; //filtered result return
        });
    }
  }

}
