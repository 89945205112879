/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrderStatusUpdateRequest { 
    restaurantId?: string;
    restaurantBranchId?: string;
    orderId?: string;
    orderStatus?: OrderStatusUpdateRequest.OrderStatusEnum;
    orderPaymentStatus?: OrderStatusUpdateRequest.OrderPaymentStatusEnum;
}
export namespace OrderStatusUpdateRequest {
    export type OrderStatusEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const OrderStatusEnum = {
        DRAFT: 'DRAFT' as OrderStatusEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as OrderStatusEnum,
        SUBMITTED: 'SUBMITTED' as OrderStatusEnum,
        CANCELLED: 'CANCELLED' as OrderStatusEnum,
        DECLINED: 'DECLINED' as OrderStatusEnum,
        ACCEPTED: 'ACCEPTED' as OrderStatusEnum,
        PREPARING: 'PREPARING' as OrderStatusEnum,
        PREPARED: 'PREPARED' as OrderStatusEnum,
        DELIVERED: 'DELIVERED' as OrderStatusEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as OrderStatusEnum,
        PICKEDUP: 'PICKED_UP' as OrderStatusEnum
    };
    export type OrderPaymentStatusEnum = 'PAY_LATER' | 'PAYMENT_INITIATED' | 'PAYMENT_SUCCESS' | 'PAYMENT_REFUNDED' | 'PAYMENT_FAILED' | 'PAID' | 'REFUND_INITIATED' | 'REFUND_SUCCESS' | 'PAYMENT_NA' | 'PAY_BY_CASH' | 'PAID_BY_CASH';
    export const OrderPaymentStatusEnum = {
        PAYLATER: 'PAY_LATER' as OrderPaymentStatusEnum,
        PAYMENTINITIATED: 'PAYMENT_INITIATED' as OrderPaymentStatusEnum,
        PAYMENTSUCCESS: 'PAYMENT_SUCCESS' as OrderPaymentStatusEnum,
        PAYMENTREFUNDED: 'PAYMENT_REFUNDED' as OrderPaymentStatusEnum,
        PAYMENTFAILED: 'PAYMENT_FAILED' as OrderPaymentStatusEnum,
        PAID: 'PAID' as OrderPaymentStatusEnum,
        REFUNDINITIATED: 'REFUND_INITIATED' as OrderPaymentStatusEnum,
        REFUNDSUCCESS: 'REFUND_SUCCESS' as OrderPaymentStatusEnum,
        PAYMENTNA: 'PAYMENT_NA' as OrderPaymentStatusEnum,
        PAYBYCASH: 'PAY_BY_CASH' as OrderPaymentStatusEnum,
        PAIDBYCASH: 'PAID_BY_CASH' as OrderPaymentStatusEnum
    };
}