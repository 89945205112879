import { ActivatedRoute } from '@angular/router';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProfileModel, CartItemModel, CartModel, CartRestControllerForUsersService, RestaurantBranchModel, RestaurantBranchTableModel, RestaurantModel, SmartDineResponseCartModel } from '../../../swagger';
import { LoaderService } from '../loader/loader.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { Role } from 'src/app/common/enums/role.enum';

export interface CartItems {
  [key: string]: CartItemModel;
}

export interface TableInfo {
  restaurant: RestaurantModel;
  restaurantBranch: RestaurantBranchModel;
  table: RestaurantBranchTableModel;
}

export interface CartSNotification {
  action: 'open-cart';
}

@Injectable({
  providedIn: 'root',
})
export class CartService {

  private notificationSubject = new Subject<CartSNotification>();

  private cart: CartModel | null = null;
  private subject: BehaviorSubject<CartItems> = new BehaviorSubject<CartItems>({});

  private restaurantId: string | null = null;
  private restaurantBranchId: string | null = null;
  private tableId: string | null = null;
  public userProfile: ProfileModel | null = null;
  public isAdmin = false;
  public isRestoAdmin = false;
  public isCustomer = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public cartRestControllerForUsersService: CartRestControllerForUsersService,
    private loaderService: LoaderService,
    private userProfileService: UserProfileService,
  ) {
    this.init();
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        if(userProfile){
          this.userProfile = userProfile;
          this.isAdmin = userProfile.roles.includes(Role.ADMINS);
          this.isRestoAdmin = userProfile.roles.includes(Role.RESTO_ADMINS);
          this.isCustomer = userProfile.roles.includes(Role.USERS);
        }
      }
    });
    console.log(`${this.isAdmin} ${this.isCustomer} ${this.isRestoAdmin}`);
  }

  public init(options?: { restaurantId: string, restaurantBranchId: string, tableId: string }) {
    let isInitialized = false;
    if (options) {
      this.restaurantId = options.restaurantId;
      this.restaurantBranchId = options.restaurantBranchId;
      this.tableId = options.tableId;
      isInitialized = true;
    }

    if (!isInitialized) {
      const queryParams = this.activatedRoute.snapshot.queryParams;
      this.restaurantId = queryParams?.restaurantId || null;
      this.restaurantBranchId = queryParams?.restaurantBranchId || null;
      this.tableId = queryParams?.tableId || null;
    }

    if (this.restaurantId && this.restaurantBranchId) {
      this.loadCart();
    }
  }

  public getCart() {
    return this.cart;
  }

  public setCart(cart: CartModel) {
    this.cart = cart;

    // TODO: noOfItems, cartPriceModel, tableId
    if (!cart.noOfItems) {
      cart.noOfItems = '0';
    }
    if (!cart.cartPriceModel) {
      cart.cartPriceModel = {
        totalPrice: 0,
        totalPriceWithDiscountAndTax: 0,
      };
    }
    if (!cart.tableId) {
      cart.tableId = this.tableId;
    }

    this.subject.next(cart.cartItemModels);
  }

  public clearCart() {
    this.subject.next({});
    this.cart = null;
  }

  public createCart() {
    const cart = {
      addressId: null,
      cartId: null,
      cartItemModels: {},
      cartPriceModel: {
        totalPrice: 0,
        totalPriceWithDiscountAndTax: 0,
      },
      cartStatus: 'DRAFT',
      cartTaxInfoModels: null,
      orderType: 'DINE_IN',
      noOfItems: '0',
      restaurantId: this.restaurantId,
      restaurantBranchId: this.restaurantBranchId,
      tableId: this.tableId,
    } as CartModel;

    this.cartRestControllerForUsersService.createCart(cart).subscribe({
      next: (smartDineResponseCartModel: SmartDineResponseCartModel) => {
        console.info('Cart created successfully!', smartDineResponseCartModel);
        this.setCart(smartDineResponseCartModel.item);
      },
      error: (err) => {
        console.error('Unable to create new cart!', err);
      }
    });
  }

  public loadCart() {
        this.createCart();
  }

  public updateCart() {
    const cartItems = this.subject.getValue();
    const items = Object.values(cartItems);

    // Updating total items count..
    const totalItems = items.reduce((acc, each) => acc + each.quantity, 0);
    this.cart.noOfItems = '' + totalItems;
  }

  public saveCart(cartStatus?: 'DRAFT' | 'ORDERED' | 'ORDERING_INPROGRESS' | 'RETURNED' | 'NOT_ACCEPTED', isAdmin?: boolean) {
    this.loaderService.showLoader();
    if (cartStatus) {
      this.cart.cartStatus = cartStatus;
    }
    this.updateCart();
    this.cartRestControllerForUsersService.updateItemInCart(this.cart, this.cart.cartId).subscribe({
      next: (res: SmartDineResponseCartModel) => {
        this.setCart(res.item);
        console.log('Final Cart Reponse', res);
        this.loaderService.hideLoader();
      },
      error: (err) => {
        console.error('Unable to update exsting cart!', err);
        this.loaderService.hideLoader();
      }
    });
  }

  public removeCart() {
    if (!this.cart) { return; }
    this.clearCart();
    console.log('Cart Deleted!');
    this.init();
  }

  public getItems(): Observable<CartItems> {
    return this.subject.asObservable();
  }

  public getItem(menuItemMapId: string): CartItemModel | null {
    let cartItems = this.subject.getValue();
    return cartItems[menuItemMapId] || null;
  }

  public setItem(cartItem: CartItemModel) {
    const cartItems = this.subject.getValue();
    cartItems[cartItem.menuItemMapId] = cartItem;
    this.subject.next(cartItems);
  }

  public removeItem(cartItem: CartItemModel) {
    const cartItems = this.subject.getValue();
    delete (cartItems[cartItem.menuItemMapId]);
    this.subject.next(cartItems);
  }

  public getNotifications(): Observable<CartSNotification> {
    return this.notificationSubject.asObservable();
  }

  public notify(message: CartSNotification) {
    this.notificationSubject.next(message);
  }
}