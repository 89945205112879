import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ItemQuantity, MenuItemEntity, MenuItemRestControllerService, SmartdineImageRestControllerService, SmartDineResponseMenuItemEntity } from 'src/app/swagger';
import { LoaderService } from '../../services/loader/loader.service';


@Component({
  selector: 'app-item-upsertion',
  templateUrl: './item-upsertion.component.html',
  styleUrls: ['./item-upsertion.component.scss'],
})
export class ItemUpsertionComponent implements OnInit {

  public static readonly id = 'item-upsertion-modal';

  @Input()
  public itemId: string | null = null;

  @Input()
  public menuId: string | null = null;

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  public item: MenuItemEntity | null = null;

  public form: FormGroup;

  public tempMenuId: string | null = null; // TODO: Needs removal

  isQuantitySelected: boolean = true;

  specializations = ['👨‍🍳 Chef Special', '🔥 High Calories', '🥗 Healthy', '🌶️ Hot & Spicy', '🍬 Low Sugar'];

  constructor(
    private menuItemRestControllerService: MenuItemRestControllerService,
    private loaderService: LoaderService,
    private toastController: ToastController,
    private modalController: ModalController,
    private smartDineImage: SmartdineImageRestControllerService,
  ) {
    this.createForm();
  }

  get isNew(): boolean {
    return !this.itemId;
  }

  ngOnInit() {
    this.setInitialItemType();
    this.init();
  }

  public onSaveClick() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.save();
    }
  }

  public onCloseClick() {
    this.modalController.dismiss(null, '', ItemUpsertionComponent.id);
  }

  private init() {
    if (this.itemId) { // Updating existing one..
      this.setItem();
    }
  }

  private createForm() {

    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      template: new FormControl(false, Validators.required),
      vegetarian: new FormControl(false, Validators.required),
      itemType: new FormControl('FOOD', Validators.required),
      tags: new FormControl([]),
      iconUrl: new FormControl(''),
      menuIcon: new FormControl(''),
      quantityItemType: new FormControl('', []),
      itemQuantity: new FormGroup({
        value: new FormControl(''),
        measurementUnit: new FormControl('')
      })

    });
  }

  setInitialItemType(): void {
    const value = this.form.get('itemQuantity.value').value;
    this.isQuantitySelected = !this.specializations.includes(value);
  }

  onItemTypeChange(event: any) {
    const value = event.detail.value;
    this.isQuantitySelected = value === 'quantity';
  }

  public save() {
    if (this.form.invalid) { return; }

    const item = {
      itemId: null,
      categoryIds: [],
      restaurantId: this.restaurantId,
      restaurantBranchId: this.restaurantBranchId
    } as MenuItemEntity;

    if (this.item) {
      Object.assign(item, this.item);
    }
    Object.assign(item, this.form.value);
    console.log('item', item);
    this.upsertItem(item);
  }

  private upsertItem(item: MenuItemEntity) {
    this.menuItemRestControllerService.createMenuItem(item, this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuItemEntity) => {
        const response = {} as { createdItem?: MenuItemEntity, updatedItem?: MenuItemEntity };
        if (this.isNew) {
          response.createdItem = res.item;
        } else {
          response.updatedItem = res.item;
        }
        this.modalController.dismiss(response, '', ItemUpsertionComponent.id);
        this.showToast('Details saved successfully.');
      },
      error: (err) => {
        console.error('Unable to upsert item!', err);
      }
    });
  }

  private setItem() {
    this.menuItemRestControllerService.getMenuItem(this.itemId, this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuItemEntity) => {
        this.item = res.item;
        this.form.patchValue(this.item);
      },
      error: (err) => {
        console.error('Unable to get menu item details!', err);
      }
    });
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  /**
   * Method handler to be called on image file browsing/drop.
   */
   onImageSelection(event) {
    const files = event.target.files;
    if (files.length) {
      this.uploadImage(files[0]);
    }
  }

  /**
   * Upload Image.
   * @param file Image File.
   */
  uploadImage(imageFile: any) {
    const menuId = this.tempMenuId || this.menuId; // TODO: Bug in API; Need fixing..
    const fileKey = this.item?.iconImage?.key;
    let uploadRequest = this.smartDineImage.uploadFileForm(imageFile, this.restaurantId, this.restaurantBranchId, menuId); // TODO: this.menuId
    if (fileKey) {
      this.loaderService.showLoader();
      uploadRequest = this.smartDineImage.updateFileForm(fileKey, imageFile, this.restaurantId, this.restaurantBranchId, this.menuId);
    }

    this.loaderService.showLoader();
    uploadRequest.subscribe(
      (res) => {
        console.info('Image uploaded successfully!');
        this.item.iconImage = res.item;
        this.loaderService.hideLoader();
      },
      (err) => {
        console.error('Unable to upload image!', err);
        this.loaderService.hideLoader();
      }
    );
  }
}
