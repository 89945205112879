import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { CalendarModal, CalendarModalOptions, CalendarResult } from 'ion2-calendar';
import {
  MenuItemMapPriceEntity,
  MenuItemMapPriceRestControllerService,
  MenuItemMapStockEntity,
  MenuItemMapStockRestControllerService,
  ProfileModel,
  SmartDineResponseMenuItemMapPriceEntity,
  SmartDineResponseMenuItemMapStockEntity,
} from 'src/app/swagger';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { MenuItemMapping } from '../menu-item-mappings/menu-item-mappings.component';
import { OverlayEventDetail } from '@ionic/core';



@Component({
  selector: 'app-item-price-selecion',
  templateUrl: './item-price-selecion.component.html',
  styleUrls: ['./item-price-selecion.component.scss'],
})
export class ItemPriceSelecionComponent implements OnInit {

  public static readonly id = 'item-price-modal';

  @Input()
  public menuId: string | null = null;

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  @Input()
  public priceHistory: any | null = null;

  @Input()
  public priceFlag: 'price' | 'stock' = 'price';

  @Input()
  public menuItemMapping: MenuItemMapping | null = null;

  public priceForm: FormGroup;
  public stockForm: FormGroup;

  private userProfile: ProfileModel | null = null;

  public itemPriceList: any | null = null;
  public itemPriceHistory = [];
  public priceId:string | null = null;
  public menuItemMapPriceId:string | null = null;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private menuItemMapPriceRestControllerService: MenuItemMapPriceRestControllerService,
    private menuItemMapStockRestControllerService: MenuItemMapStockRestControllerService,
    private userProfileService: UserProfileService,
  ) { }

  ngOnInit() {
    this.setUserProfile();
    if (this.priceFlag === 'price') {
      this.createPriceForm();
      const priceDetails = this.menuItemMapping?.priceDetails || {};
      this.menuItemMapPriceId = (this.menuItemMapping.priceDetails) ?this.menuItemMapping.priceDetails.menuItemMapPriceId: null;
      this.priceForm.patchValue(priceDetails);
    } else {
      this.createStockForm();
      const stockDetails = this.menuItemMapping?.stockDetails || {};
      this.stockForm.patchValue(stockDetails);
    }
  
    const { itemPriceList, itemPriceHistory } = this.getPriceHistoryData(this.priceHistory, this.menuItemMapping);
  
    this.itemPriceList = itemPriceList;
    this.itemPriceHistory = itemPriceHistory;
  }

  public onSaveClick() {
    this.stockForm?.markAllAsTouched();
    this.priceForm?.markAllAsTouched();

    if (this.priceFlag === 'stock' && this.stockForm.valid) {
      this.saveNewStock();
    } else if (this.priceForm.valid) {
      this.saveNewPrice();
    }
  }

  public onCloseClick() {
    this.modalController.dismiss(null, 'close', ItemPriceSelecionComponent.id);
  }

  private setUserProfile() {
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
  }

  async openCalendarToDt() {
    const options: CalendarModalOptions = {
      title: 'To Date',
      cssClass: 'datepicker-modal',
      closeLabel: 'Close',
      autoDone: true,
    };

    const myCalendar = await this.modalController.create({
      component: CalendarModal,
      componentProps: { options }
    });

    myCalendar.present();

    const event: OverlayEventDetail = await myCalendar.onDidDismiss();
    const date = (event.data as CalendarResult)?.dateObj || null;
    if (event.role === 'done' && date) {
      this.priceForm.get('toDate').setValue(date.toISOString());
    }
  }

  async openCalendarFromDt() {
    const options: CalendarModalOptions = {
      title: 'From Date',
      cssClass: 'datepicker-modal',
      closeLabel: 'Close',
      autoDone: true,
    };

    const myCalendar = await this.modalController.create({
      component: CalendarModal,
      componentProps: { options }
    });

    myCalendar.present();

    const event: OverlayEventDetail = await myCalendar.onDidDismiss();
    const date = (event.data as CalendarResult)?.dateObj || null;
    if (event.role === 'done' && date) {
      this.priceForm.get('fromDate').setValue(date.toISOString());
    }
  }

  async openCalendarDt() {
    const options: CalendarModalOptions = {
      title: 'Stock Date',
      cssClass: 'datepicker-modal',
      closeLabel: 'Close',
      autoDone: true,
    };

    const myCalendar = await this.modalController.create({
      component: CalendarModal,
      componentProps: { options }
    });

    myCalendar.present();

    const event: OverlayEventDetail = await myCalendar.onDidDismiss();
    const date = (event.data as CalendarResult)?.dateObj || null;
    if (event.role === 'done' && date) {
      this.stockForm.get('date').setValue(date.toISOString());
    }
  }

  private createPriceForm() {
    this.priceForm = new FormGroup({
      price: new FormControl(0, [Validators.required]),
      costPrice: new FormControl(0, [Validators.required]),
      fromDate: new FormControl(new Date().toISOString(), [Validators.required]),
      toDate: new FormControl(new Date().toISOString(), [Validators.required]),
    }, (controls: AbstractControl) => {
      const fromDate = new Date(controls.get('fromDate').value);
      const toDate = new Date(controls.get('toDate').value);
      if (fromDate > toDate) {
        return { lowerToDate: 'From Date should be less than To Date!' };
      }
      return null;
    });
  }

  private createStockForm() {
    this.stockForm = new FormGroup({
      menuItemStockMapId: new FormControl(null),
      reservedQuantity: new FormControl(0, [Validators.required]),
      availableQuantity: new FormControl(0, [Validators.required]),
      totalQuantity: new FormControl(0, [Validators.required]),
      date: new FormControl(new Date().toISOString(), [Validators.required]),
      stockCheckEnabled: new FormControl(false, [Validators.required]),
    }, (formGroup: AbstractControl) => {
      const availableQuantity = formGroup.get('availableQuantity').value;
      const totalQuantity = formGroup.get('totalQuantity').value;
      if (availableQuantity <= totalQuantity) { return null; }
      return { error: 'Available Quantity should be less that or equal to Total Quantity!' };
    });
  }

  private saveNewPrice() {
    const newPriceDetails = {
      menuItemMapPriceId: null,
      menuItemMapId: this.menuItemMapping.menuItemMapId,
      restaurantBranchId: this.restaurantBranchId,
      restaurantId: this.restaurantId,
      fromDate: new Date(),
      toDate: new Date(),
      active: true,
      price: 0,
      costPrice:0,
    } as MenuItemMapPriceEntity;
    Object.assign(newPriceDetails, this.priceForm.value);

    if(!this.menuItemMapping.hasOwnProperty('priceDetails') || this.menuItemMapping.priceDetails === null || this.menuItemMapPriceId === null){
      this.menuItemMapPriceRestControllerService.createMenuItemMapPrice(
        newPriceDetails, this.restaurantId, this.restaurantBranchId, this.menuItemMapping.menuItemMapId)
        .subscribe({
        next: (res) => {
          console.log('Price Details Created Successfully ',res.item);
          this.modalController.dismiss({ value: res.item }, 'save', ItemPriceSelecionComponent.id);
          this.getPriceHistoryData(this.priceHistory, this.menuItemMapping);
        },
        error: (err) => {
          console.error('Unable to create new price details!', err);
        }
      });
    } else {
      this.menuItemMapPriceRestControllerService.updateMenuItemMapPrice( 
        newPriceDetails, this.restaurantId, this.restaurantBranchId, this.menuItemMapping.menuItemMapId,this.menuItemMapPriceId)
        .subscribe({
        next: (res) => {
          console.log('Price Details Updated Successfully ',res.item)
          this.modalController.dismiss({ value: res.item }, 'save', ItemPriceSelecionComponent.id);
          this.getPriceHistoryData(this.priceHistory, this.menuItemMapping);
        },
        error: (err) => {
          console.error('Unable to update new price details!', err);
        }
      });
    }
  }

  private saveNewStock() {
    const newStockDetails = {
      menuItemStockMapId: null,
      restaurantId: this.restaurantId,
      restaurantBranchId: this.restaurantBranchId,
      menuItemMapId: this.menuItemMapping.menuItemMapId,
      date: null,
      availableQuantity: 0,
      reservedQuantity: 0,
      totalQuantity: 0,
      createdAt: new Date(),
      creator: this.userProfile.userId,
      active: true,
      stockCheckEnabled: false
    } as MenuItemMapStockEntity;
    const newStack = Object.assign(newStockDetails, this.stockForm.value);
    //const oldStack = Object.assign({...newStack}, { date: null });
    Promise.all([
      this.makeStockCreationRequest(newStack),
      //this.makeStockCreationRequest(oldStack),
    ]).then((res: [void | SmartDineResponseMenuItemMapStockEntity]) => {
      if (res[0]) {
        this.modalController.dismiss({ value: res[0].item }, 'save', ItemPriceSelecionComponent.id);
      }
    });
  }

  private makeStockCreationRequest(stockDetails) {
    return this.menuItemMapStockRestControllerService.createMenuItemMapStock(
      stockDetails, this.restaurantId, this.restaurantBranchId, this.menuItemMapping.menuItemMapId
    ).toPromise().catch((err) => {
      console.error('Unable to update stock details!', stockDetails, err);
    });
  }

  private getPriceHistoryData(priceHistory: any[], menuItemMapping: any) {
    let itemPriceList = [];
    let itemPriceHistory = [];
  
    if (priceHistory) {
      priceHistory.forEach((data: { menuItemId: string; priceHistory: any; })=>{
        if(menuItemMapping.menuItemMapId === data.menuItemId){
          itemPriceList = data.priceHistory;
        }
      });
    }
  
    if (itemPriceList) {
      const priceDetails = menuItemMapping.priceDetails;
      const currentPriceExists = itemPriceList.find((item: MenuItemMapPriceEntity) => item.menuItemMapPriceId === this.priceId);
      if (!currentPriceExists && priceDetails) {
        // Filter out any existing price history data that has the same date range as the new price details
        itemPriceList = itemPriceList.filter((item: MenuItemMapPriceEntity) => {
          return !(priceDetails.fromDate >= item.fromDate && priceDetails.fromDate <= item.toDate) &&
            !(priceDetails.toDate >= item.fromDate && priceDetails.toDate <= item.toDate) &&
            !(priceDetails.fromDate <= item.fromDate && priceDetails.toDate >= item.toDate);
        });
  
        itemPriceList.push(priceDetails);
      } else if (currentPriceExists && priceDetails) {
        const index = itemPriceList.findIndex((item: MenuItemMapPriceEntity) => item.menuItemMapPriceId === this.priceId);
  
        itemPriceList = itemPriceList.filter((item: MenuItemMapPriceEntity) => {
          return !(priceDetails.fromDate >= item.fromDate && priceDetails.fromDate <= item.toDate) &&
            !(priceDetails.toDate >= item.fromDate && priceDetails.toDate <= item.toDate) &&
            !(priceDetails.fromDate <= item.fromDate && priceDetails.toDate >= item.toDate) ||
            (item.menuItemMapPriceId === this.priceId);
        });
  
        itemPriceList.splice(index, 1, priceDetails);
      }
  
      itemPriceHistory = [...itemPriceList];
    }
  
    return {
      itemPriceList,
      itemPriceHistory
    };
  }
  
}
