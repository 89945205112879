/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ItemQuantity } from './itemQuantity';
import { OfferRuleModel } from './offerRuleModel';
import { OrderItemTaxInfoModel } from './orderItemTaxInfoModel';

export interface OrderItemModel { 
    menuItemMapId?: string;
    menuName?: string;
    itemId?: string;
    menuItemType?: OrderItemModel.MenuItemTypeEnum;
    quantity?: number;
    unitPrice?: number;
    price?: number;
    priceWithDiscount?: number;
    outOfStock?: boolean;
    messageKey?: string;
    itemName?: string;
    itemDescription?: string;
    createdAt?: Date;
    orderItemTaxInfoModels?: { [key: string]: OrderItemTaxInfoModel; };
    itemQuantity?: ItemQuantity;
    discountAmount?: number;
    offerRuleModel?: OfferRuleModel;
}
export namespace OrderItemModel {
    export type MenuItemTypeEnum = 'FOOD' | 'LIQUOR' | 'FOOD_5' | 'FOOD_12' | 'FOOD_18';
    export const MenuItemTypeEnum = {
        FOOD: 'FOOD' as MenuItemTypeEnum,
        LIQUOR: 'LIQUOR' as MenuItemTypeEnum,
        FOOD5: 'FOOD_5' as MenuItemTypeEnum,
        FOOD12: 'FOOD_12' as MenuItemTypeEnum,
        FOOD18: 'FOOD_18' as MenuItemTypeEnum
    };
}