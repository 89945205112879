import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AlertController, MenuController, PopoverController } from '@ionic/angular';
import { Action } from '../../services/header/header.service';
import { AuthService } from './../../../core/services/auth/auth.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { ProfileModel } from '../../../swagger';
import { Router } from '@angular/router';
import { HomeComponent } from 'src/app/core/components/home/home.component';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../services/storage/storage.service';
import { RestaurantsService } from '../../services/restaurants/restaurants.service';

const languages = [
  {
    name: 'English',
    code: 'en',
  },
  // {
  //   name: 'English(US)',
  //   code: 'en',
  // },
  // {
  //   name: 'हिंदी (Hindi)',
  //   code: 'hi-IN',
  // },
  // {
  //   name: 'বাঙালি (Bangali)',
  //   code: 'ba-IN',
  // },
  // {
  //   name: 'తెలుగు (Telugu)',
  //   code: 'te-IN',
  // },
  // {
  //   name: 'தமிழ் (Tamil)',
  //   code: 'ta-IN',
  // },
  
];

@Component({
  selector: 'app-popover',
  template: `
    <ion-list>
      <ion-item button lines="none" [routerLink]="dashboardLink" (click)="onDashboardClick($event)">
        <ion-icon name="grid-outline" class="mx-1"></ion-icon>
        <ion-label class="mx-1">Dashboard</ion-label>
      </ion-item>
      <ion-item *ngIf="!userProfile?.roles.includes('Users')" button lines="none" [routerLink]="'/user-profile'" (click)="onItemClick($event)">
        <ion-icon slot="start" name="person-circle-outline"></ion-icon>
        <ion-label>
          <h2 *ngIf="userFullName else emailId" [innerText]="userFullName"></h2>
          <p *ngIf="userFullName" [innerText]="userProfile?.emailId"></p>
          <ng-template #emailId><h2 [innerText]="userProfile?.emailId"></h2></ng-template>
        </ion-label>
      </ion-item>
      <ion-item button lines="none" (click)="onSwitchClick($event)">
        <ion-icon slot="start" name="sync-circle-outline"></ion-icon>
        <ion-label>
         Switch Account
        </ion-label>
      </ion-item>
      <ion-item button lines="none" (click)="onLogoutClick($event)">
        <ion-icon slot="start" name="log-out-outline"></ion-icon>
        <ion-label>Logout</ion-label>
      </ion-item>
    </ion-list>
  `,
  styles: [
    `
    ion-icon[slot="start"] {
      margin-inline-end: 16px;
    }
    `
  ]
})
export class PopoverComponent implements OnInit {

  public static readonly id = 'profile-options-modal';
  public userProfile: ProfileModel | null = null;
  public userFullName = '';
  public dashboardLink: string | null = null;
  public userType: string | null = null

  constructor(
    private popoverController: PopoverController,
    private authService: AuthService,
    private userProfileService: UserProfileService,
    private router: Router,
  ) { }

  public onItemClick(_: MouseEvent) {
    this.popoverController.dismiss('', '', PopoverComponent.id);
  }

  public onDashboardClick(_: Event) {
    this.router.navigateByUrl(this.dashboardLink);
    this.popoverController.dismiss('', '', PopoverComponent.id);
  }

  public onLogoutClick(_: MouseEvent) {
    this.authService.clearSession();
    this.popoverController.dismiss("logout", "", PopoverComponent.id);
    this.userProfileService.setUserProfile(null);
    this.router.navigateByUrl('/');
  }

  public onSwitchClick(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.authService.clearSession();
    this.popoverController.dismiss("switch", "", PopoverComponent.id);
    this.router.navigateByUrl('/login');
  }

  public ngOnInit() {
    this.init();
  }

  private init() {
    this.setUserProfile();
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userFullName = '';
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
        if(userProfile){
          this.userType = this.userProfile.roles[0];
          this.updateDashboardLink(this.userType);
        }
        if ( userProfile && (userProfile.firstName && userProfile.lastName)) {
          this.userFullName = userProfile.firstName + ' ' + userProfile.lastName;
        }
      }
    });
  }

  public updateDashboardLink(user_type:string){
    if(user_type === 'Users'){
      this.dashboardLink = '/customer/home';
    }else if(user_type === 'RestaurantAdmins'){
      this.dashboardLink = '/resto-admin/home';
    }else if(user_type === 'Admins'){
      this.dashboardLink = '/admins/home';
    }
  }
}

@Component({
  selector: 'help-popover',
  template: `
    <div class="help-popover">
      <div class="close-icon d-flex justify-content-end p-2" (click)="dismissPopover()"
      style="position: absolute;
      top: -5px;
      right: -5px;">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </div>
      <div class="info-text text-danger px-2 pt-3 mb-2">For help and support, please create ticket using the Help link</div>
      <div class="label-text px-3 py-2">
        <a (click)="openHelpPage()" class="label-text">Help & Support</a>
      </div>
    </div>`,
  styles: [`
    .help-popover, help-popover{
      overflow: hidden !important;
    }
    .info-text{
      font: normal normal normal 11px/15px Chivo;
    }
    .label-text{
      font: normal normal 600 15px/21px Poppins !important;
      text-decoration: none;
      cursor: pointer !important;
      color: #0089ff !important;
      padding: 5px 8px !important;
    }
    .label-text:hover {
      color: #0089ff !important;
      cursor: pointer !important;
    }
    ion-icon[slot="start"] {
      margin-inline-end: 16px;
    }
    `,
  ],
})
export class HelpPopoverComponent implements OnInit {
  public static readonly id= "help-support-modal";
  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}

  public dismissPopover() {
    this.popoverController.dismiss();
  }

  public openHelpPage() {
    window.open('https://smartdinenow.raiseaticket.com', '_blank');
    this.popoverController.dismiss();
  }
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  private readonly storageKeyLangauge = 'selected-language';

  public userProfile: ProfileModel | null = null;
  public actions: Action[] = [];

  public customLogoLocation: string = null;

  public languages = languages;

  public selectedLanguage = 'en';

  public logoUrl:string | null = null;

  constructor(
    private menuController: MenuController,
    private popoverController: PopoverController,
    private userProfileService: UserProfileService,
    public alertController: AlertController,
    private authService: AuthService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private router: Router,
    private restaurantService: RestaurantsService,
  ) {

    const parsedUrl = new URL(window.location.href);

    if (parsedUrl && (parsedUrl.hostname == 'dev-bmgrand.smartdinenow.com' || parsedUrl.hostname == 'bmgrand.smartdinenow.com')) {
      this.customLogoLocation = 'assets/img/bmgrandlogo.png'
    }

  }


  // public async onLanguageChange(event: CustomEvent) {
  //   const langCode = event.detail.value;
  //   this.translateService.use(langCode);
  //   await this.storageService.set(this.storageKeyLangauge, langCode);
  // }

  public ngOnInit() {
    this.init();
    this.getRestaurantLogo();
  }

  public async onMenuClick(_: Event) {
    await this.menuController.enable(true, HomeComponent.sideMenuId);
    await this.menuController.toggle(HomeComponent.sideMenuId);
  }

  public onActionClick(event: Event, action: Action) {
    if (action.handler) {
      action.handler(event);
    }
  }

  public ionViewWillEnter() {
    this.menuController.enable(true, HomeComponent.sideMenuId);
  }

  public async onAvatarClick(event: MouseEvent) {
    const popover = await this.popoverController.create({
      id: PopoverComponent.id,
      component: PopoverComponent,
      event,
      translucent: true
    });
    popover.onDidDismiss().then((res)=>{
      if(!res || !res.data) return;
      if(res.data === "logout"){
        this.userProfile = null;
        this.setUserProfile();
      }else if(res.data === "switch"){
        this.router.navigateByUrl('/login');
      }
    });
    return await popover.present();
  }

  public async onHelpClick(event: MouseEvent) {
    const popover = await this.popoverController.create({
      id: HelpPopoverComponent.id,
      component: HelpPopoverComponent,
      event,
      translucent: true
    });
    popover.onDidDismiss().then((res)=>{
      if(!res || !res.data) return;
    });
    return await popover.present();
  }

  public async onExitButtonClick() {
    this.alertController.create({
      header: 'SmartdineNow',
      message: 'Do you want to exit?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          this.authService.clearSession();
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  private init() {
    this.setUserProfile();
    this.setLanguage();
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
  }

  private setLanguage() {
    this.storageService.get(this.storageKeyLangauge).then(
      (langCode: string | null) => {
        if (langCode) {
          this.selectedLanguage = langCode;
          this.translateService.use(langCode);
        }
      },
    );
  }

  private getRestaurantLogo(){
    this.restaurantService.getLogoUrl().subscribe({
      next: (url: string |null) =>{
        this.logoUrl = url;
      },
      error: (error) =>{
        console.log('Can not able to get the Logo!', error.message);
      }
    })
  }
}
