import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { RestaurantsService } from '../../services/restaurants/restaurants.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { SmartdineImageRestControllerService, RestaurantEntity, ProfileModel, RestaurantRestControllerService, SmartDineResponseRestaurantEntity, GeoLocation, SmartDineResponseRestaurantModel  } from 'src/app/swagger';
import { LoaderService } from '../../services/loader/loader.service';
import { RestaurantAdminModel } from 'src/app/swagger/model/restaurantAdminModel';
import { AddressComponent } from '../maps/address/address.component';
import { RestaurantRegistrationForAdminService } from 'src/app/swagger/api/restaurantRegistrationForAdmin.service';
import { SmartDineResponseRestaurantForAdmin } from 'src/app/swagger/model/smartDineResponseRestaurantForAdmin';
@Component({
  selector: 'app-restaurant-upsertion',
  templateUrl: './restaurant-upsertion.component.html',
  styleUrls: ['./restaurant-upsertion.component.scss'],
})
export class RestaurantUpsertionComponent implements OnInit {

  @Input()
  public restaurantId: string | null = null;

  public static readonly id = 'resto-upsertion-modal';
  
  public restaurantEntity: RestaurantEntity | null = null;
  public restaurantAdminEntity: RestaurantAdminModel | null = null;
  private userProfile: ProfileModel | null = null;

  public restaurantForm: FormGroup | null = null;
  public updateRestaurantForm: FormGroup | null = null;
  
  @Input() restaurantTitle: string = 'New Restaurant';

  // Define the options for dropdown fields
  public countries = ['India', 'USA'];
  public indiaStates = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Lakshadweep', 'Delhi', 'Puducherry'];
  public usaStates = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
  
  public countryCodes = ['+91', '+1'];
  public timeZones = ['Asia/Kolkata', 'US/Central'];
  public currencyCodes = ['INR', 'USD'];

  get restaurantStatus() {
    return Object.keys(RestaurantAdminModel.RestaurantStatusEnum);
  }

  get orderTypes() {
    return Object.keys(RestaurantAdminModel.AllowedOrderTypesEnum);
  }

  get paymentPreferences() {
    return Object.keys(RestaurantAdminModel.AllowedPaymentPreferencesEnum);
  }

  constructor(
    private modalController: ModalController,
    private userProfileService: UserProfileService,
    public formBuilder: FormBuilder, 
    private restaurantRestControllerService: RestaurantRestControllerService,
    private toastController: ToastController, 
    private loaderService: LoaderService, 
    private smartdineImageRestControllerService: SmartdineImageRestControllerService,
    private registrationServiceForAdmin: RestaurantRegistrationForAdminService

  ) {
      this.createForm();
      this.newUpdateRestoForm();
    }

  private createForm() {
    this.restaurantForm = this.formBuilder.group({
      restaurantName: new FormControl('', [Validators.required]),
      restaurantLogoUrl: new FormControl('', []),
      restaurantDescription: new FormControl('', []),
      restaurantCode: new FormControl('', []),
      fssai: new FormControl('', []),
      gstId: new FormControl('', []),
      restaurantStatus: new FormControl('', []),
      prefTimeZone: new FormControl('', []),
      maxNoOfTables: new FormControl(1, []),
      maxNoOfRestaurantBranches: new FormControl(1, []),
      prefCurrencyCode: new FormControl('', []),

      restaurantBranchCode: new FormControl('', []),
      restaurantBranchName: new FormControl('', [Validators.required]),
      restaurantBranchFssai: new FormControl('', []),
      restaurantBranchGstId: new FormControl('', []),
      restaurantBranchEmailId: new FormControl('', []),
      restaurantBranchContactNo: new FormControl('', []),
      restaurantBranchAddress: new FormControl('', []),
      restaurantBranchWebsite: new FormControl('', []),
      restaurantBranchCity: new FormControl('', []),
      restaurantCountry: new FormControl('India', []),
      restaurantBranchState: new FormControl('', []),
      restaurantBranchCountryCode: new FormControl('+91', []),
      allowedPaymentPreferences: new FormControl('', []),
      allowedOrderTypes:new FormControl('', []),
      restaurantAdminUserId: new FormControl('', []),
      restaurantSubscriptionEntitiy: new FormControl('', []),
      location: new FormControl({latitude: 0, longitude: 0} as GeoLocation, []),
      
    });
  }

  private newUpdateRestoForm(){
    this.updateRestaurantForm = this.formBuilder.group({
      name: new FormControl('',[Validators.required]),
      description: new FormControl('',[Validators.required]),
      restaurantCode: new FormControl('',[]),
      gstIN: new FormControl('',[]),
      fssai: new FormControl('',[]),
      restaurantStatus: new FormControl('',[]),
      maxNoOfTables: new FormControl(1,[]),
      maxNoOfRestaurantBranches: new FormControl(1,[]),
      restaurantSubscriptionEntityId: new FormControl('', []),
    })
  }

  public ngOnInit() {
    this.setUserProfile();
    if (this.restaurantId) {
      this.setRestaurant();
      this.restaurantForm = null;
    }else{
      this.updateRestaurantForm = null;
    }
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
  }

  private setRestaurant() {
    this.restaurantRestControllerService.getRestaurant(this.restaurantId).subscribe({
      next: (res) => {
        this.restaurantEntity = res.item;
        this.updateRestaurantForm.patchValue(this.restaurantEntity);
      },
      error: (err) => {
        console.error('Unable to get Restaurant details!', err);
      }
    });
  }

  public onCloseClick() {
    this.modalController.dismiss('', '', RestaurantUpsertionComponent.id);
  }

  public onSaveClick() {
    if (this.restaurantForm?.valid && !this.restaurantId) {
      this.restaurantForm?.markAllAsTouched();
      this.registerRestaurant();
    }else if(this.updateRestaurantForm?.valid && this.restaurantId){
      this.updateRestaurantForm?.markAllAsTouched();
      this.updateRestaurant();
    }
  }

  private registerRestaurant() {
    const restaurantModel = this.restaurantAdminEntity || {} as RestaurantAdminModel;
    Object.assign(restaurantModel, this.restaurantForm.value);
    restaurantModel['restaurantId'] = restaurantModel.restaurantName.replace(/\s+/g, '') + 'RestaurantId'
    restaurantModel['restaurantBranchId'] = restaurantModel.restaurantBranchName.replace(/\s+/g, '') + 'BranchId'
    this.registerNewRestaurant(restaurantModel);
  }

  private registerNewRestaurant(restaurantModel: RestaurantAdminModel) {
    this.registrationServiceForAdmin.createRestaurant(restaurantModel).subscribe({
      next: (res: SmartDineResponseRestaurantForAdmin) => {
        let data = {'refresh': 'true', 'type': 'created', 'restaurant': res.item};
        this.modalController.dismiss( data, '', RestaurantUpsertionComponent.id);
        this.showToast(`Restaurant - ${ res.item['name'] } Created Successfully!`);
      },
      error: (err) => {
        console.error('Unable to create Restaurant!', err);
        this.showToast(`Restaurant - ${ restaurantModel.restaurantName } Creation Failed!`);
      }
    });
  }

  private updateRestaurant(){
    const updatedFormData = this.updateRestaurantForm?.value;
    this.updatedRestaurant(updatedFormData);
  }

  private updatedRestaurant(restaurantModel: RestaurantEntity) {
    this.restaurantRestControllerService.updateRestaurant(restaurantModel,this.restaurantId).subscribe({
      next: (res: SmartDineResponseRestaurantModel) => {
        let data = {'refresh': 'true', 'type': 'updated', 'restaurant': res.item};
        this.modalController.dismiss( data, '', RestaurantUpsertionComponent.id);
        this.showToast(`Restaurant - ${ res.item['name'] } Updated Successfully!`);
      },
      error: (err) => {
        console.error('Unable to create Restaurant!', err);
        this.showToast(`Restaurant - ${ restaurantModel.name } Creation Failed!`);
      }
    });
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  /**
   * Method handler to be called on image file browsing/drop.
   */
   onImageSelection(event) {
    const files = event.target.files;
    if (files.length && this.restaurantEntity.restaurantId) {
      this.uploadImage(files[0]);
    }
  }

  /**
   * Upload Image.
   * @param file Image File.
   */
  uploadImage(imageFile: any) {
    const restaurantId = this.restaurantEntity.restaurantId; // TODO: Bug in API; Need fixing..
    const fileKey = this.restaurantEntity?.iconImage?.key;
    let uploadRequest = this.smartdineImageRestControllerService.uploadFileForm(imageFile, this.restaurantId); // TODO: this.menuId
    if (fileKey) {
      this.loaderService.showLoader();
      uploadRequest = this.smartdineImageRestControllerService.updateFileForm(fileKey, imageFile, this.restaurantId);
    }

    this.loaderService.showLoader();
    uploadRequest.subscribe(
      (res) => {
        console.info('Image uploaded successfully!');
        this.restaurantEntity.iconImage = res.item;
        this.loaderService.hideLoader();
      },
      (err) => {
        console.error('Unable to upload image!', err);
        this.loaderService.hideLoader();
      }
    );
  }


  // Function to update state options based on selected country
  onCountryChange(event:Event) {
    if (event['detail'].value === 'India') {
      this.restaurantForm.get('restaurantBranchState').setValue('');
    } else if (event['detail'].value === 'USA') {
      this.restaurantForm.get('restaurantBranchState').setValue('');
    }
  }

  public showMap(locationFormControl: FormControl) {
    this.showMapPopup(locationFormControl);
  }

  public async showMapPopup(locationControl: any) {
    const geolocation: GeoLocation = locationControl.value;
    const model = await this.modalController.create({
      component: AddressComponent,
      id: AddressComponent.id,
      componentProps: {
        location: geolocation
      }
    });
   model.onDidDismiss().then((selLocation) => {
     locationControl.setValue({latitude: selLocation.data.location.lat, longitude: selLocation.data.location.lng});
    });
    await model.present();
  }
 
}
