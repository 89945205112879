/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrderStatusUpdateResponse { 
    restaurantId?: string;
    restaurantBranchId?: string;
    orderId?: string;
    currentOrderStatus?: OrderStatusUpdateResponse.CurrentOrderStatusEnum;
    prevOrderStatus?: OrderStatusUpdateResponse.PrevOrderStatusEnum;
}
export namespace OrderStatusUpdateResponse {
    export type CurrentOrderStatusEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const CurrentOrderStatusEnum = {
        DRAFT: 'DRAFT' as CurrentOrderStatusEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as CurrentOrderStatusEnum,
        SUBMITTED: 'SUBMITTED' as CurrentOrderStatusEnum,
        CANCELLED: 'CANCELLED' as CurrentOrderStatusEnum,
        DECLINED: 'DECLINED' as CurrentOrderStatusEnum,
        ACCEPTED: 'ACCEPTED' as CurrentOrderStatusEnum,
        PREPARING: 'PREPARING' as CurrentOrderStatusEnum,
        PREPARED: 'PREPARED' as CurrentOrderStatusEnum,
        DELIVERED: 'DELIVERED' as CurrentOrderStatusEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as CurrentOrderStatusEnum,
        PICKEDUP: 'PICKED_UP' as CurrentOrderStatusEnum
    };
    export type PrevOrderStatusEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const PrevOrderStatusEnum = {
        DRAFT: 'DRAFT' as PrevOrderStatusEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as PrevOrderStatusEnum,
        SUBMITTED: 'SUBMITTED' as PrevOrderStatusEnum,
        CANCELLED: 'CANCELLED' as PrevOrderStatusEnum,
        DECLINED: 'DECLINED' as PrevOrderStatusEnum,
        ACCEPTED: 'ACCEPTED' as PrevOrderStatusEnum,
        PREPARING: 'PREPARING' as PrevOrderStatusEnum,
        PREPARED: 'PREPARED' as PrevOrderStatusEnum,
        DELIVERED: 'DELIVERED' as PrevOrderStatusEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as PrevOrderStatusEnum,
        PICKEDUP: 'PICKED_UP' as PrevOrderStatusEnum
    };
}