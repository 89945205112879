import { QuantityChangeEvent } from './../quantity-selector/quantity-selector.component';
import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  MenuItemEntity,
  MenuItemMapEntity,
  MenuItemMapPriceRestControllerService,
  MenuItemMapStockRestControllerService,
  MenuItemMapRestControllerService,
  MenuRestControllerService,
  SmartDineResponseMenuItemMapEntity,
  MenuItemMapPriceEntity,
  MenuItemMapStockEntity,
  SmartDineResponseMenuItemMapPriceEntity,
  SmartDineResponseMenuItemMapStockEntity,
  MenuUsersRestControllerService,
  SmartDineResponseMenuItemMapModel,
  CartItemModel
} from 'src/app/swagger';
import { SdEvent } from '../../interfaces/event';
import { ItemSelectionComponent } from '../item-selection/item-selection.component';
import { ItemPriceSelecionComponent } from '../item-price-selecion/item-price-selecion.component';
import { CartService } from '../../services/cart/cart.service';
import * as moment from 'moment';

export interface MenuItemMapping extends MenuItemMapEntity {
  priceDetails?: MenuItemMapPriceEntity | null;
  stockDetails?: MenuItemMapStockEntity | null;
  cartItem?: CartItemModel | null;
}
@Component({
  selector: 'app-sd-menu-item-mappings',
  templateUrl: './menu-item-mappings.component.html',
  styleUrls: ['./menu-item-mappings.component.scss'],
})

export class MenuItemMappingsComponent implements OnInit {


  @Input()
  public menuId: string | null = null;

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  @Input()
  public tableId: string | null = null;

  @Input()
  public menuItemMappings: MenuItemMapping[] = [];

  @Input() enableToolbar = true;

  public forCustomer = false;

  @Output() sdChange = new EventEmitter<SdEvent>();
  public priceHistory = [];

  constructor(
    private router: Router,
    private menuItemMapRestControllerService: MenuItemMapRestControllerService,
    private menuItemMapPriceRestControllerService: MenuItemMapPriceRestControllerService,
    private menuItemMapStockRestControllerService: MenuItemMapStockRestControllerService,
    private menuUsersRestControllerService: MenuUsersRestControllerService,
    private modalController: ModalController,
    private cartService: CartService,
  ) {
    const currentUrl = this.router.routerState.snapshot.url;
    this.forCustomer = currentUrl.startsWith('/customer');
  }

  ngOnInit() {
    this.init();
  }

  public onAddItemClick() {
    this.showItemSelectionPopup();
  }

  public onPriceEditClick(_event: MouseEvent, item: MenuItemMapping, priceFlag: 'price' | 'stock') {
    if (this.forCustomer) { return; }
    this.showPricePopup(item, priceFlag);
  }

  public onRemoveClick(event: MouseEvent, mapping: MenuItemMapEntity, index: number) {
    this.menuItemMapRestControllerService.deleteMenuItemMap(
      mapping.menuItemMapId, this.restaurantId, this.restaurantBranchId
    ).subscribe({
      next: (_: SmartDineResponseMenuItemMapEntity) => {
        this.menuItemMappings.splice(index, 1);
        this.sdChange.emit({ items: this.menuItemMappings });
      },
      error: (err) => {
        console.error('Unable to remove menu item mapping!', err);
      }
    });
  }

  public onQuantityChange(event: QuantityChangeEvent, item: MenuItemMapping) {
    const cartItem = item.cartItem;
    const quantity = event.value;
    cartItem.quantity = quantity;
    cartItem.price = cartItem.unitPrice * quantity;
    if (quantity === 0) {
      this.cartService.removeItem(cartItem);
    } else {
      this.cartService.setItem(cartItem);
    }
  }

  public init() {
    if (this.forCustomer) {
      this.setMenuItemMappingsForCustomer();
    } else {
      this.setMenuItemMappings();
    }
  }

  private setMenuItemMappings() {
    this.menuItemMappings = [];
    this.sdChange.emit({ items: this.menuItemMappings });
    this.menuItemMapRestControllerService.getMenuItemMaps1(this.menuId, this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuItemMapEntity) => {
        this.menuItemMappings = res.items;
        this.sdChange.emit({ items: this.menuItemMappings });
        if (this.restaurantBranchId) {
          this.menuItemMappings.forEach((menuItemMap) => {
            this.setMenuItemMapPrices(menuItemMap);
            this.setMenuItemMapStocks(menuItemMap);
          });
        }
      },
      error: (err) => {
        console.error('Unable to get menu items!', err);
      }
    });
  }

  private setMenuItemMappingsForCustomer() {
    this.menuItemMappings = [];
    this.sdChange.emit({ items: this.menuItemMappings });
    this.menuUsersRestControllerService.getMenuItemMaps(this.restaurantId, this.restaurantBranchId, this.menuId).subscribe({
      next: (res: SmartDineResponseMenuItemMapModel) => {
        this.menuItemMappings = res.items.map((each) => {
          return {
            itemId: each.itemId,
            menuId: each.menuId,
            menuItemMapId: each.menuItemMapId,
            restaurantId: each.restaurantId,
            restaurantBranchId: each.restaurantBranchId,
            menuItemEntity: each.menuItemModel,
            priceDetails: {
              price: each.price,
              fromDate: each.orderableFromTime,
              active: true, // TODO: each.orderable,
              message: each.message,
            },
            cartItem: null,
          } as MenuItemMapping;
        });

        this.menuItemMappings.forEach((each) => {
          this.setMenuItemCartInfo(each);
        });

        this.sdChange.emit({ items: this.menuItemMappings });
      },
      error: (err) => {
        console.error('Unable to get menu mappings!', err);
      }
    });

    // Updating the menu item mapping cart info in case of value changes in cart service..
    this.cartService.getItems().subscribe({
      next: () => {
        this.menuItemMappings.forEach((each) => {
          this.setMenuItemCartInfo(each);
        });
      }
    });
  }

  private setMenuItemMapPrices(menuItemMap: MenuItemMapping) {
    this.menuItemMapPriceRestControllerService.getMenuItemMapPrices(
      this.restaurantId, this.restaurantBranchId, menuItemMap.menuItemMapId
    ).subscribe({
      next: (res: SmartDineResponseMenuItemMapPriceEntity) => {
        console.log(res);
        let priceHistory = {'menuItemId':menuItemMap.menuItemMapId,'priceHistory':res.items};
        this.priceHistory.push(priceHistory);
        const activePriceDetails = res.items?.find((each) => {
          if (!each.active) { return false; }
          const fromTime = new Date(each.fromDate).getTime();
          const toTime = new Date(each.toDate).getTime();
          const currentTime = new Date().getTime();
          return (currentTime >= fromTime && currentTime <= toTime);
        });
        menuItemMap.priceDetails = activePriceDetails || null;
      },
      error: (err) => {
        console.error('Unable to get prices!', err);
      }
    });
  }

  private setMenuItemMapStocks(menuItemMap: MenuItemMapping) {
    this.menuItemMapStockRestControllerService.getMenuItemMapStocks(
      this.restaurantId, this.restaurantBranchId, menuItemMap.menuItemMapId, new Date()
    ).subscribe({
      next: (res: SmartDineResponseMenuItemMapStockEntity) => {
        menuItemMap.stockDetails = res.items[res.items.length - 1] || null;
      },
      error: (err) => {
        console.error('Unable to get price!', err);
      }
    });
  }

  private setMenuItemCartInfo(menuItemMap: MenuItemMapping) {
    let cartItem = this.cartService.getItem(menuItemMap.menuItemMapId);
    if (!cartItem) {
      cartItem = {
        itemId: menuItemMap.itemId,
        menuItemMapId: menuItemMap.menuItemMapId,
        menuItemType: CartItemModel.MenuItemTypeEnum.FOOD,
        unitPrice: menuItemMap.priceDetails.price,
        quantity: 0,
        price: 0,
        itemName: menuItemMap.menuItemEntity.name,
        itemDescription: menuItemMap.menuItemEntity.description,
      } as CartItemModel;
    }

    menuItemMap.cartItem = cartItem;
  }

  public async showItemSelectionPopup() {
    const modal = await this.modalController.create({
      id: ItemSelectionComponent.id,
      component: ItemSelectionComponent,
      componentProps: {
        menuId: this.menuId, // Of which menu the items are getting selected..
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
        selectedItemMaps: this.menuItemMappings,
      }
    });
    modal.onDidDismiss().then((res) => {
      if (!res || !res.data) { return; }
      let mappings = [];
      if (res.data.createdMappings) {
        mappings = this.menuItemMappings.concat(res.data.createdMappings);
      }

      const deletedMappings = res.data.deletedMappings as MenuItemMapEntity[];
      if (deletedMappings) {
        const deletedMappingIds = deletedMappings.map(e => e.menuItemMapId);
        mappings = mappings.filter((mapping) => !deletedMappingIds.includes(mapping.menuItemMapId));
      }

      this.menuItemMappings = mappings;
      this.sdChange.emit({ items: this.menuItemMappings });
    });
    await modal.present();
  }

  public async showPricePopup(menuItemMapping: MenuItemMapping, priceFlag: 'price' | 'stock') {
    const modal = await this.modalController.create({
      id: ItemPriceSelecionComponent.id,
      component: ItemPriceSelecionComponent,
      componentProps: {
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
        priceHistory: this.priceHistory,
        menuItemMapping,
        priceFlag,
      }
    });
    modal.onDidDismiss().then((res) => {
      const value = res?.data?.value;
      if (!value) { return; }
      if (priceFlag === 'price') {
        menuItemMapping.priceDetails = value;
      } else {
        menuItemMapping.stockDetails = value;
      }
    });
    await modal.present();
  }
}
