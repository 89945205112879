import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CartRestControllerForAdminsService } from './api/cartRestControllerForAdmins.service';
import { CartRestControllerForUsersService } from './api/cartRestControllerForUsers.service';
import { CategoryProductMapRestControllerService } from './api/categoryProductMapRestController.service';
import { CategoryRestControllerForAdminService } from './api/categoryRestControllerForAdmin.service';
import { CommonRestControllerForUsersService } from './api/commonRestControllerForUsers.service';
import { DomainConfigRestControllerService } from './api/domainConfigRestController.service';
import { DomainConfigRestControllerForCommonService } from './api/domainConfigRestControllerForCommon.service';
import { GroupRestControllerService } from './api/groupRestController.service';
import { InventoryAlertControllerService } from './api/inventoryAlertController.service';
import { InventoryEntryControllerService } from './api/inventoryEntryController.service';
import { InventoryMasterControllerService } from './api/inventoryMasterController.service';
import { KitchenOrderRestControllerService } from './api/kitchenOrderRestController.service';
import { MenuItemMapPriceRestControllerService } from './api/menuItemMapPriceRestController.service';
import { MenuItemMapRestControllerService } from './api/menuItemMapRestController.service';
import { MenuItemMapStockRestControllerService } from './api/menuItemMapStockRestController.service';
import { MenuItemRestControllerService } from './api/menuItemRestController.service';
import { MenuRestControllerService } from './api/menuRestController.service';
import { MenuUsersRestControllerService } from './api/menuUsersRestController.service';
import { OfferMappingRestControllerService } from './api/offerMappingRestController.service';
import { OfferRestControllerForUsersService } from './api/offerRestControllerForUsers.service';
import { OfferRuleRestControllerService } from './api/offerRuleRestController.service';
import { OrderRestControllerService } from './api/orderRestController.service';
import { OrderRestControllerForUsersService } from './api/orderRestControllerForUsers.service';
import { ParentOrderRestControllerService } from './api/parentOrderRestController.service';
import { PaymentOptionRestControllerService } from './api/paymentOptionRestController.service';
import { PaymentOptionRestControllerForUsersService } from './api/paymentOptionRestControllerForUsers.service';
import { PaymentTransactionRestControllerForUsersService } from './api/paymentTransactionRestControllerForUsers.service';
import { PaymentTransactionRestControllerService } from './api/paymentTransactionRestController.service';
import { ProductRestControllerForAdminService } from './api/productRestControllerForAdmin.service';
import { ProfileRestControllerForUsersService } from './api/profileRestControllerForUsers.service';
import { RazorPayWebhookControllerService } from './api/razorPayWebhookController.service';
import { ReportsRestControllerForAdminService } from './api/reportsRestControllerForAdmin.service';
import { RestaurantBranchRestControllerService } from './api/restaurantBranchRestController.service';
import { RestaurantBranchTableRestControllerService } from './api/restaurantBranchTableRestController.service';
import { RestaurantContactRestControllerService } from './api/restaurantContactRestController.service';
import { RestaurantGroupMapRestControllerService } from './api/restaurantGroupMapRestController.service';
import { RestaurantQrCodeRestControllerService } from './api/restaurantQrCodeRestController.service';
import { RestaurantRegistrationRestControllerForUsersService } from './api/restaurantRegistrationRestControllerForUsers.service';
import { RestaurantRestControllerService } from './api/restaurantRestController.service';
import { RestaurantTaxRuleMapRestControllerService } from './api/restaurantTaxRuleMapRestController.service';
import { RestaurantUsersRestControllerService } from './api/restaurantUsersRestController.service';
import { SmartdineImageRestControllerService } from './api/smartdineImageRestController.service';
import { StatisticsRestControllerService } from './api/statisticsRestController.service';
import { TaxRuleRestControllerService } from './api/taxRuleRestController.service';
import { UserGroupMapRestControllerService } from './api/userGroupMapRestController.service';
import { UserRestControllerService } from './api/userRestController.service';
import { RestaurantCurrencyService } from './api/restaurantCurrency.service';
import { RedirectUserService } from './api/redirectUser.service';
import { GooglePlacesService } from './api/google-places.service';
import { RestaurantRegistrationForAdminService } from './api/restaurantRegistrationForAdmin.service';
@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CartRestControllerForAdminsService,
    CartRestControllerForUsersService,
    CategoryProductMapRestControllerService,
    CategoryRestControllerForAdminService,
    CommonRestControllerForUsersService,
    DomainConfigRestControllerService,
    DomainConfigRestControllerForCommonService,
    GroupRestControllerService,
    InventoryAlertControllerService,
    InventoryEntryControllerService,
    InventoryMasterControllerService,
    KitchenOrderRestControllerService,
    MenuItemMapPriceRestControllerService,
    MenuItemMapRestControllerService,
    MenuItemMapStockRestControllerService,
    MenuItemRestControllerService,
    MenuRestControllerService,
    MenuUsersRestControllerService,
    OfferMappingRestControllerService,
    OfferRestControllerForUsersService,
    OfferRuleRestControllerService,
    OrderRestControllerService,
    OrderRestControllerForUsersService,
    ParentOrderRestControllerService,
    PaymentOptionRestControllerService,
    PaymentOptionRestControllerForUsersService,
    PaymentTransactionRestControllerForUsersService,
    PaymentTransactionRestControllerService,
    ProductRestControllerForAdminService,
    ProfileRestControllerForUsersService,
    RazorPayWebhookControllerService,
    ReportsRestControllerForAdminService,
    RestaurantBranchRestControllerService,
    RestaurantBranchTableRestControllerService,
    RestaurantContactRestControllerService,
    RestaurantGroupMapRestControllerService,
    RestaurantQrCodeRestControllerService,
    RestaurantRegistrationRestControllerForUsersService,
    RestaurantRestControllerService,
    RestaurantTaxRuleMapRestControllerService,
    RestaurantUsersRestControllerService,
    SmartdineImageRestControllerService,
    StatisticsRestControllerService,
    TaxRuleRestControllerService,
    UserGroupMapRestControllerService,
    UserRestControllerService,
    RestaurantCurrencyService,
    RedirectUserService,
    GooglePlacesService,
    RestaurantRegistrationForAdminService
 ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
