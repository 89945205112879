import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ProfileModel, RestaurantBranchRestControllerService, SmartDineResponseRestaurantBranchEntity } from 'src/app/swagger';
import { RestaurantBranchEntity } from 'src/app/swagger';
import { RestaurantsService } from '../../services/restaurants/restaurants.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-resto-branch-upsertion',
  templateUrl: './resto-branch-upsertion.component.html',
  styleUrls: ['./resto-branch-upsertion.component.scss'],
})
export class RestoBranchUpsertionComponent implements OnInit {

  public static readonly id = 'resto-branch-upsertion-modal';

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  public form: FormGroup;
  private branch: RestaurantBranchEntity | null = null;
  private userProfile: ProfileModel | null = null;

  constructor(
    private modalController: ModalController,
    private userProfileService: UserProfileService,
    private restaurantBranchRestControllerService: RestaurantBranchRestControllerService,
    private restaurantsService: RestaurantsService,
    private toastController: ToastController,
  ) {
    this.createForm();
  }

  public ngOnInit() {
    this.init();
  }

  public onSaveClick() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.save();
    }
  }

  public onCloseClick() {
    this.modalController.dismiss('', '', RestoBranchUpsertionComponent.id);
  }

  private init() {
    this.setUserProfile();
    if (this.restaurantBranchId) { // Updating existing one..
      this.setBranch();
    }
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
  }

  private setBranch() {
    this.restaurantBranchRestControllerService.getRestaurantBranch(this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res) => {
        this.branch = res.item;
        this.form.patchValue(this.branch);
      },
      error: (err) => {
        console.error('Unable to get branch details!', err);
      }
    });
  }

  private createForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      restaurantBranchCode: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required]),
      website: new FormControl('', [Validators.required]),
      contactNumber: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      gstIN: new FormControl('', [Validators.required]),
    });
  }

  private save() {
    const branch = this.branch || {} as RestaurantBranchEntity;
    Object.assign(branch, this.form.value);
    if (this.branch) {
      branch.modifier = this.userProfile.userId;
      branch.modifiedAt = new Date();
    } else {
      branch.restaurantBranchId = null;
      branch.creator = this.userProfile.userId;
      branch.createdAt = new Date();
      branch.restaurantId = this.restaurantId;
      branch.restaurantBranchImages = [];
    }
    this.upsertBranch(branch);
  }

  private upsertBranch(branch: RestaurantBranchEntity) {
    this.restaurantBranchRestControllerService.createRestaurantBranch(branch, this.restaurantId).subscribe({
      next: (res: SmartDineResponseRestaurantBranchEntity) => {
        console.log('Branch Created Successfully!', res.item.name);
        this.restaurantsService.getBranches(true);
        this.modalController.dismiss('', '', RestoBranchUpsertionComponent.id);
        this.showToast(`Branch - ${ branch.name } Created Successfully!`);
      },
      error: (err) => {
        console.error('Unable to create brach!', err);
        this.showToast(`Branch - ${ branch.name } Creation Failed!`);
      }
    });
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

}
